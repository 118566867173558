<template>
  <div class="page-listings">
    <cashmanagementdetails-tour v-if="$store.state.tour & !loading" />
    <transition name="menu-popover" v-if="PDFload === true">
      <section class="lightbox">
        <div class="flex-box-wrap pt150 pb150">
          <div class="flex-internal">
            <atom-spinner
              :animation-duration="1000"
              :size="40"
              :color="'#fff'"
            />
            <p class="text-white">Loading PDF file......</p>
          </div>
        </div>
      </section>
    </transition>

    <transition name="menu-popover" v-if="PDFfile != null || PDFError == true">
      <div v-if="PDFfile != null || PDFError == true" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <div v-if="PDFError == true" class="pdfError">
          <div>
            <img src="@/assets/img/sad.svg" style="border: none" />
            <h5 class="text-white">
              Sorry, this invoice either isn't available,
              <br />or hasn't been printed yet.
            </h5>
          </div>
        </div>

        <embed
          v-else
          width="90%"
          height="100%"
          title="If you are having issues viewing this PDF, your browser security settings are set to download PDFs instead of viewing in the browser. You can also try using Chrome instead of Edge."
          :src="'data:application/pdf;base64,' + this.PDFfile"
        />
      </div>
    </transition>

    <section
      v-if="loading"
      class="py-5 bg9 bs-shadow mobile-top"
    >
      <div class="container">
        <h1 class="text-white mb0 lh1">Loading Cash Management....</h1>
        <h2 class="text-white lh1"></h2>
        <p class="text-white lh1">Loading details</p>
      </div>
    </section>

    <section v-else class="py-5 bg9 bs-shadow mobile-top">
      <div id="cashdetails-step-1" class="container">
        <h1 class="text-white mb0 lh1">{{ invoices[0].name }}</h1>
        <!-- <h2 class="text-white lh1">Invoiced: {{details[0].name}}</h2>
        <h2 class="text-white lh1">Discounted: {{details[0].name}}</h2>-->
        <h5 class="text-white lh1 mb5">
          <b>Amount Invoiced:</b>
          <span v-if="invoices.length">{{ invoices[0].invoiced | currency  }}</span>
          <span v-else>0</span>
        </h5>
        <h5 class="text-white lh1">
          <b>Amount Discounted:</b>
          <span v-if="invoices.length">{{ invoices[0].discounted | currency  }}</span>
          <span v-else>0</span>
        </h5>
      </div>
    </section>

    <div class="container">
      <div class="row">
        <div
          v-if="loading == true"
          class="col-xs-12 col-sm-12 col-12 col-lg-12"
        >
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner
                :animation-duration="1000"
                :size="40"
                :color="'#575757'"
              />
              <p>Loading......</p>
            </div>
          </div>
        </div>

        <div
          v-else-if="loading == false && !filteredInvoices.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
        >
          <div class="sorry-box">
            <img src="@/assets/img/sad.svg" />
            <h3>Sorry, no invoice was found...</h3>
            <button class="btn-primary" @click="firstLoad">
              Show me some Invoices
            </button>
          </div>
        </div>

        <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-12">
          <div
            class="
              detail-header
              d-flex
              align-items-center
              p-3
              mt-3
              mb10
              rounded
              box-shadow
            "
          >
            <p class="mrauto mb0">
              Page {{ currPage }} of
              {{ Math.ceil(this.filteredInvoices.length / 200) }}
            </p>
            <div class="pag-wrapper mlauto">
              <div class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
                    this.currPage === this.totalPage ||
                    this.currPage ==
                      Math.ceil(this.filteredInvoices.length / 200)
                  "
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>

          <div>
            <div class="listing">
              <div class="d-flex number-showing mb20">
                <router-link
                  :to="{ path: '/cash-management' }"
                  class="mb0 lh1 mrauto list-btn dib d-flex center"
                  style="display: flex"
                >
                  <i class="material-icons">keyboard_arrow_left</i>
                  Back
                </router-link>
                <download-csv
                  id="cashdetails-step-2"
                  class="mb0 lh1 mlauto list-btn dib d-flex center"
                  :data="detailsCSV"
                  :name="invoices[0].name + '.csv'"
                  >Download CSV</download-csv
                >
              </div>
              <!--
              <h2 class data-config-id="header">AC Aging Report Single</h2>
              -->
              <!-- Aging Data -->
              <div class="rfr table-responsive">
                <table class="table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">AC Name</th>
                      <th scope="col">0-30</th>
                      <th scope="col">31-45</th>
                      <th scope="col">46-70</th>
                      <th scope="col">71+</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-bind:key="invoice.acid" v-for="invoice in aging">
                      <td>
                        <router-link :to="/cash-management/ + invoice.acid">
                          {{ invoice.acName + " (" + invoice.acid + ")" }}
                        </router-link>
                      </td>
                      <td>
                        <p class="mb5">{{ invoice.bucket1 | currency }}</p>
                      </td>
                      <td>
                        <p class="mb5">{{ invoice.bucket2 | currency }}</p>
                      </td>
                      <td>
                        <p class="mb5">{{ invoice.bucket3 | currency }}</p>
                      </td>
                      <td>
                        <p class="mb5">{{ invoice.bucket4 | currency }}</p>
                      </td>
                      <td>
                        <p class="mb5">{{ invoice.total | currency }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- card -->

              <div class="rfr table-responsive">
                <table class="table-striped table-bordered">
                  <thead>
                    <tr>
                      <th
                        @click="sortCol('invoiceDate')"
                        class="table-link ten"
                        :class="
                          columnSort == 'invoiceDate' ? 'column-active' : ''
                        "
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          Invoice Date
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'invoiceDate'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('invoiceNo')"
                        class="table-link ten"
                        :class="
                          columnSort == 'invoiceNo' ? 'column-active' : ''
                        "
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          Invoice #
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'invoiceNo'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('projName')"
                        class="table-link thirty"
                        :class="columnSort == 'projName' ? 'column-active' : ''"
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          Order Name
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'projName'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('orderNo')"
                        class="table-link ten"
                        :class="columnSort == 'orderNo' ? 'column-active' : ''"
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          Order #
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'orderNo'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('invoiceAmount')"
                        class="table-link twenty"
                        :class="
                          columnSort == 'invoiceAmount' ? 'column-active' : ''
                        "
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          Original Invoice
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'invoiceAmount'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('invOpenAmount')"
                        class="table-link ten"
                        :class="
                          columnSort == 'invOpenAmount' ? 'column-active' : ''
                        "
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          Open Total
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'invOpenAmount'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('dueDate')"
                        class="table-link ten"
                        :class="columnSort == 'dueDate' ? 'column-active' : ''"
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          Due Date
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'dueDate'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-bind:key="index"
                      v-for="(detail, index) in filteredInvoices.slice(
                        pageStart,
                        pageStart + countOfPage
                      )"
                    >
                      <td>
                        {{ detail.invoiceDate }}
                      </td>
                      <td>
                        <p class="mb5">
                          <a
                            id="cashdetails-step-3"
                            class="pdfButton"
                            @click="getPDF(detail.invoiceNo)"
                            >{{ detail.invoiceNo }}</a
                          >
                        </p>
                      </td>
                      <td>
                        <p class="mb5">{{ detail.projName }}</p>
                      </td>
                      <td>
                        <router-link
                          :to="/order-details/ + detail.orderNo"
                          id="cashdetails-step-4"
                        >
                          {{ detail.orderNo }}
                        </router-link>
                      </td>
                      <td>
                        <p class="mb5">{{ detail.invoiceAmount | currency }}</p>
                      </td>
                      <td>
                        <p class="mb5">{{ detail.invOpenAmount | currency }}</p>
                      </td>
                      <td>
                        <p class="mb5">
                          {{ detail.dueDate }}
                        </p>
                        <!--
                        <p
                          class="mb5"
                        >{{ formatDueDate(moment(detail.invoiceDate).format("MM-DD-YYYY")) }}</p>

                        
                        <p class="mb5">{{ formatDueDate(moment(detail.invoiceDate)) }}</p>
                        -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- card -->
            </div>
          </div>

          <div
            class="
              detail-header
              d-flex
              align-items-center
              p-3
              mb-3
              mt10
              rounded
              box-shadow
            "
          >
            <p class="mrauto mb0">
              Page {{ currPage }} of
              {{ Math.ceil(this.filteredInvoices.length / 200) }}
            </p>
            <div class="pag-wrapper mlauto">
              <div class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
                    this.currPage === this.totalPage ||
                    this.currPage ==
                      Math.ceil(this.filteredInvoices.length / 200)
                  "
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- end other column -->
      </div>
    </div>
    <!-- container -->
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from "vue-avatar";
import Datepicker from "vuejs-datepicker";
import JsonCSV from "vue-json-csv";
import moment from "moment";
import cashmanagementdetailsTour from "../components/Tours/cashmanagementdetailsTour";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + wontworkqwd2iwedjqoewiudwo
  },
};

export default {
  name: "CashManagementSingle",
  components: {
    AtomSpinner,
    Avatar,
    Datepicker,
    JsonCSV,
    cashmanagementdetailsTour,
  },
  data() {
    return {
      ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
      ACId: this.$route.params.id,
      backend: process.env.VUE_APP_SERVER_URL,
      searchValue: "",
      search: "",
      storedListings: [],
      searchRender: false,
      searchStarted: false,
      details: [],
      acs: [],
      invoices: [],
      detailsCSV: [],
      aging: [],
      PDFload: false,
      PDFfile: null,
      PDFError: false,
      selectedCat: "Show All",
      acFilter: "",
      searching: false,
      loading: true,
      countOfPage: 200,
      currPage: 1,
      noRGAFound: false,
      forumCats: [
        { id: 0, name: "Show All" },
        { id: 1, name: "Configura" },
        { id: 2, name: "Marketing" },
        { id: 3, name: "Other" },
        { id: 4, name: "Process" },
        { id: 5, name: "Product" },
        { id: 6, name: "Spacenet" },
      ],
      colSort: false,
      columnSort: "",
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.searchValue = null;
      this.acFilter = null;
      this.searchStarted = false;
      this.$store.commit("setActivePage", "order-manage");
      //return axios.get(this.endPointCompute, auth).then((response) => {
      return axios
        .get(`${this.ordermanageapi}/cashmanagement/${this.ACId}`, auth)
        .then(
          (response) => {
            this.aging = response.data.aging;
            this.invoices = response.data.summary;
            this.details = response.data.details;
            //console.log(CSV);
            this.loading = false;

            document
              .getElementById("mobileScrollTarget")
              .scrollIntoView({ behavior: "smooth" });

            let CSV = this.details.map((detail) => {
              if (detail.dueDate.length) {
                detail.dueDate = this.moment(detail.dueDate).format(
                  "MM-DD-YYYY"
                );
                detail.invoiceDate = this.moment(detail.invoiceDate).format(
                  "MM-DD-YYYY"
                );
                this.detailsCSV.push(detail);
              }
            });
            //this.detailsCSV = CSV;
            //this.detailsCSV.splice(0, this.detailsCSV.length, ...CSV);
          },
          (error) => {
            if (error.response.status === 401) {
              //this.router.push("/");
              window.location.href = "/";
            }
          }
        )
        .then(() => {
          return axios
            .get(`${this.ordermanageapi}/aclist?active=true`, auth)
            .then(
              (response, acs) => {
                //console.log(response);
                this.acs = response.data;
                this.loading = false;
              },
              (error) => {
                if (error.response.status === 401) {
                  //this.router.push("/");
                  window.location.href = "/";
                }
              }
            );
        });
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    searchReset: function () {
      this.selectedCat = "Show All";
    },

    setPage: function (idx) {
      this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      this.currPage = idx;
    },

    catSwitcher: function (catType) {
      //console.log(catType);
      let category = catType;
      //console.log(category);
      if (category !== "Show All") {
        this.selectedCat = category;
        this.search = "";
        //console.log(category);
      } else {
        this.selectedCat = "Show All";
      }
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    getPDF: function (id) {
      let PDFID = id;
      this.PDFload = true;
      return axios
        .get(`${this.ordermanageapi}/pdf?type=Invoice&id=${PDFID}`, auth)
        .then((response) => {
          //console.log(response);
          if (response.data.pdf.length) {
            this.PDFfile = response.data.pdf[0].pdfblog;
          } else {
            this.PDFError = true;
          }
          this.PDFload = false;
        });
    },
    closeLightbox: function () {
      this.PDFfile = null;
      this.PDFload = false;
      this.PDFError = false;
    },
    formatDueDate: function (due) {
      let oldDate = moment(due, "MM/DD/YYYY");
      let monthDay = Number(moment(oldDate).format("DD"));
      let day = 10;

      if (monthDay > day) {
        //it is after, make it the 10th two months out
        let computed = moment(oldDate, "MM/DD/YYYY").date(day).add(2, "M");
        return moment(computed).format("MM-DD-YYYY");
      } else {
        //it is befor make it the 10th of next month
        let computed = moment(oldDate, "MM/DD/YYYY").date(day).add(1, "M");
        return moment(computed).format("MM-DD-YYYY");
      }
    },
    sortCol: function (type) {
      this.columnSort = type;
      const COLUMN = type;
      console.log(COLUMN);
      if (this.colSort) {
        this.details.sort((a, b) => (a[COLUMN] > b[COLUMN] ? 1 : -1));
      } else {
        this.details.sort((a, b) => (a[COLUMN] < b[COLUMN] ? 1 : -1));
      }
      this.colSort = !this.colSort;
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    filteredInvoices: function () {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.selectedCat !== "Show All") {
        this.search = "";
        //console.log(this.selectedCat);
        return this.details.filter((f) => {
          return f.Type == this.selectedCat;
        });
      } else {
        return this.details.filter((f) => {
          return f.total != 0;
        });
      }
    },

    foundNoRGA: function () {
      if (this.search.length > 1 && this.filteredInvoices.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },

    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },

    totalPage: function () {
      return Math.ceil(this.details.length / this.countOfPage);
    },

    /*
    generateNewCSVValues: function() {
      console.log("ran");
      var obj = {};

      if (this.details.length) {
        console.log("has data");
        //let newData = this.details;
        this.details.forEach(function(element, index) {
          console.log(element.dueDate);
          
          this.newData[index].dueDate = this.formatDueDate(
            element.dueDate
		  ).toString();
		  
          this.downloadData.push(element);
        });

        //this.downloadData = this.newData;
        return;
      }
	}
	*/
  },
  created() {
    this.$store.commit("showNavigation");
    // console.log(this.$route.path);
  },

  mounted() {
    document
      .getElementById("mobileScrollTarget")
      .scrollIntoView({ behavior: "smooth" });
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}

.detail {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.detail-bottom {
  align-items: flex-end;
}
.detail-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.listing {
  overflow: scroll;
}

.listing {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}

.table-responsive {
  display: table;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.listing {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
}
.table-striped {
  min-width: 100%;
  overflow: scroll;
}
.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
}
.search-btns .clear {
  margin-left: auto;
}
.search-btns .search {
  margin-left: 5px;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}

@media all and (min-width: 768px) {
  .dates {
    display: flex;
    color: #757575 !important;
  }
}
.top-search {
  color: #757575;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
.center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.pdfButton {
  cursor: pointer;
  text-decoration: underline;
  color: #00b4b4;
}
.pdfButton:hover {
  text-decoration: underline !important;
  color: #00b4b4 !important;
}
.pdfButton:active {
  text-decoration: underline !important;
  color: #00b4b4 !important;
}
.lightbox {
  z-index: 10003 !important;
}
.pdfError {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}
</style>
